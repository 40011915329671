import { BrowserRouter, Routes, Route } from "react-router-dom"
import WelcomePage from "./pages/welcome-page"
import Navbar from "./layouts/Navbar"
import RoomsPage from "./pages/rooms"
import RoomsShowPage from "./pages/rooms-show"
import PropertiesPage from "./pages/properties"
import PropertiesShowPage from "./pages/properties-show"
import AboutUsPage from "./pages/about-us"
import RealEstatePage from "./pages/real-estate"
import MapContainer from "./layouts/MapContainer"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (


    <BrowserRouter>
      <Routes>
        <Route element={<Navbar />}>

          <Route path="/" element={<WelcomePage />} />
          <Route path="/quartos-para-alugar-em-lisboa" element={<RoomsPage />} />
          <Route path="/quartos-para-alugar-em-lisboa-ate-300-euros" element={<RoomsPage />} />

          <Route path="/propriedades" element={<PropertiesPage />} />

          <Route path="/descubra-o-seu-inquilino-ideal" element={<RealEstatePage />} />
          <Route path="/quem-somos" element={<AboutUsPage />} />

          <Route element={<MapContainer />}>
            <Route path="/quartos-para-alugar-em-lisboa/:property_name/:room_name" element={<RoomsShowPage />} />
            <Route path="/propriedades/:property_name" element={<PropertiesShowPage />} />
          </Route>
        </Route>
      </Routes>
      <ToastContainer/>
    </BrowserRouter>

  )
}

export default App
